<template>
  <div class="admin-development-commits" :class="{ skeleton: !state.loaded }">
    <div class="header">
      <div class="search">
        <div class="row">
          <div class="col project">
            <div class="dropdown">
              <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" :disabled="!state.loaded">
                <div class="ellipsis">{{ computedProjectName }}</div>
              </button>
              <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                <a class="dropdown-item" :class="{ active: state.options.projects.map(p => p.id).includes(p.id) }" v-for="(p, idx) in projects" :key="idx" @click="toggleProject(p.id)">
                  <i class="fa fa-check-square"></i>
                  <span>{{ p.name }}</span>
                </a>
              </div>
            </div>
            <span class="badge balloon bg-default color-white">프로젝트 검색</span>
          </div>
          <div class="col">
            <div class="dropdown">
              <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" :disabled="!state.loaded">
                <div class="ellipsis">{{ computedGraph }}</div>
              </button>
              <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                <a class="dropdown-item" :class="{ active: state.options.graphs.includes(g) }" v-for="(g, idx) in graphs" :key="idx" @click="toggleGraph(g)">
                  <i class="fa fa-check-square"></i>
                  <span>{{ g }}</span>
                </a>
              </div>
            </div>
            <span class="badge balloon bg-default color-white">출력 그래프</span>
          </div>
          <div class="col">
            <select class="form-control" v-model="state.options.date" @change="onChangeDate()" :disabled="!state.loaded">
              <option v-for="(d, idx) in dates" :value="d.year + lib.getNumberWithPadding(d.month)" :key="idx">
                <template>{{ d.year }}년</template>
                <template v-if="d.month"> {{ d.month }}월</template>
              </option>
            </select>
          </div>
        </div>
        <div class="refresh">
          <button class="btn wrapper" title="새로고침" @click="load()" :disabled="!state.loaded">
            <i class="fa fa-refresh"></i>
          </button>
        </div>
      </div>
    </div>
    <div class="alerts">
      <div class="alert">
        <i class="fa fa-info-circle mr-2"></i>
        <span>다음의 커밋 데이터는 통계에서 제외하였습니다.</span>
        <ul class="mt-1 mb-0">
          <li>Merge로 시작하는 제목의 커밋 데이터</li>
          <li v-for="(p, idx) in excludeProjectPaths" :key="idx">{{ p.name }} 프로젝트 {{ p.path }} 경로의 커밋 데이터</li>
        </ul>
      </div>
      <div class="alert" v-if="state.options.graphs.includes('line')">
        <i class="fa fa-info-circle mr-2"></i>
        <span>라인 그래프의 각 주차는 기준은 일요일입니다.</span>
      </div>
    </div>
    <div class="graphs">
      <div class="wrapper">
        <div class="graph" :class="{ active: m.active }" v-for="(m, idx) in state.statistics" :key="m.name + idx + state.options.graphs">
          <div class="bar" v-if="state.options.graphs.includes('bar')">
            <div class="top">
              <span class="name">{{ m.name }}</span>
              <span class="count">({{ lib.getNumberFormat(m.count) }})</span>
              <span class="remove" :title="m.active ? '삭제' : '복구'" @click="toggleMember(idx)" v-if="state.loaded">{{ m.active ? "&times;" : "&#8634;" }}</span>
            </div>
            <div class="progress">
              <div class="progress-bar" role="progressbar" :class="{ 'progress-bar-striped progress-bar-animated': m.active }" :style="{ backgroundColor: m.color, width: (m.percent.toFixed(1)) + '%' }">
                <span>{{ (m.percent === 100 ? m.percent : m.percent.toFixed(1)) + "%" }}</span>
                <span> ({{ m.ratio === 1 ? m.ratio : m.ratio.toFixed(1) }})</span>
              </div>
            </div>
          </div>
          <div class="line" v-if="state.options.graphs.includes('line')">
            <LineGraph :data="m.details" :height="150" :borderColor="m.color" :skeleton="!state.loaded"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {computed, reactive, watch} from "@vue/composition-api";
import mixin from "@/scripts/mixin";
import lib from "@/scripts/lib";
import router from "@/scripts/router";
import http from "@/scripts/http";
import LineGraph from "@/components/LineGraph";
import {httpError} from "@/scripts/httpError";

function Component(initialize) {
  this.name = "pageAdminStatsUtm";
  this.initialize = initialize;
}

export default {
  components: {LineGraph},
  mixins: [mixin],
  setup() {
    const component = new Component(() => {
      setDates();
      load();
    });

    const dates = [];
    const graphs = ["bar", "line"];

    const projects = [{
      id: "57",
      name: "omc-silverbullet"
    }, {
      id: "3",
      name: "ohmycompany"
    }, {
      id: "43",
      name: "omc-scheduler"
    }, {
      id: "65",
      name: "coss"
    }];

    const excludeProjectPaths = [{
      name: "omc-silverbullet",
      path: "omc-frontend/public/assets"
    }, {
      name: "omc-silverbullet",
      path: "omc-frontend/package-lock.json"
    }, {
      name: "omc-silverbullet",
      path: "omc-backend/src/main/resources/antisamy"
    }, {
      name: "talk",
      path: "frontend/public"
    }, {
      name: "talk",
      path: "frontend/src/mocks"
    }, {
      name: "talk",
      path: "frontend/package-lock.json"
    }];

    const state = reactive({
      loaded: false,
      options: {
        projects: [],
        graphs: [],
        date: "",
      },
      statistics: [],
    });

    const computedProjectName = computed(() => {
      const texts = [];
      if (state.options.projects.length === projects.length) {
        texts.push("전체 선택");
      } else {
        texts.push(state.options.projects[0].name);

        if (state.options.projects.length > 1) {
          texts.push(` 등 ${state.options.projects.length}개`);
        }
      }

      return texts.join("");
    });

    const computedGraph = computed(() => {
      const texts = [];

      if (state.options.graphs.length === graphs.length) {
        texts.push("전체 선택");
      } else {
        texts.push(state.options.graphs[0]);

        if (state.options.graphs.length > 1) {
          texts.push(` 등 ${state.options.graphs.length}개`);
        }
      }

      return texts.join("");
    });

    const calculate = () => {
      const members = state.statistics.filter(m => m.active);
      const maxCount = members[0].count;

      for (let m of members) {
        m.percent = m.count / maxCount * 100;
        m.ratio = maxCount / m.count;
      }
    };

    const toggleMember = (idx) => {
      state.statistics[idx].active = !state.statistics[idx].active;
      calculate();
    };

    const toggleProject = (id) => {
      const query = lib.getRenewed(router.app.$route.query);
      const projectIds = state.options.projects.map(p => p.id);
      const idx = projectIds.indexOf(id);
      idx >= 0 ? projectIds.splice(idx, 1) : projectIds.push(id);

      if (projectIds.length) {
        query.projects = window.JSON.stringify(projectIds);
      } else {
        delete query.projects;
      }

      pushToRouter(query);
    };

    const toggleGraph = (graph) => {
      const query = lib.getRenewed(router.app.$route.query);
      const idx = state.options.graphs.indexOf(graph);
      idx >= 0 ? state.options.graphs.splice(idx, 1) : state.options.graphs.push(graph);

      if (state.options.graphs.length) {
        query.graphs = window.JSON.stringify(state.options.graphs);
      } else {
        delete query.graphs;
      }

      pushToRouter(query);
    };

    const onChangeDate = () => {
      const query = lib.getRenewed(router.app.$route.query);
      query.date = state.options.date;
      pushToRouter(query);
    };

    const pushToRouter = (query) => {
      if (JSON.stringify(router.app.$route.query) === JSON.stringify(query)) {
        return;
      }

      router.push({query});
    };

    const setDates = () => {
      dates.length && dates.splice(0, dates.length - 1);

      const currDate = new window.Date();
      const currYear = currDate.getFullYear();
      const currMonth = currDate.getMonth();

      dates.push({year: currYear, month: 0});

      for (let i = currMonth; i >= 0; i -= 1) {
        dates.push({year: currYear, month: i + 1});
      }

      dates.push({year: currYear - 1, month: 0});

      for (let i = 12; i >= 1; i -= 1) {
        dates.push({year: currYear - 1, month: i});
      }
    };

    const setOptions = () => {
      const projectIds = [];
      state.options.graphs = [];

      if (router.app.$route.query.projects) {
        projectIds.push(...window.JSON.parse(router.app.$route.query.projects.toString()));
      } else {
        projectIds.push(...projects.map(p => p.id));
      }

      if (router.app.$route.query.graphs) {
        state.options.graphs = window.JSON.parse(router.app.$route.query.graphs.toString());
      } else {
        state.options.graphs = lib.getRenewed(graphs);
      }

      state.options.projects = projects.filter(p => projectIds.includes(p.id));
      state.options.date = router.app.$route.query.date || dates[1].year + lib.getNumberWithPadding(dates[1].month);
    };

    const scrollToTop = () => {
      window.scrollTo({top: 0, left: 0, behavior: "smooth"});
    };

    const load = async () => {
      setOptions();

      const dateOptions = {
        year: state.options.date.substring(0, 4),
        month: state.options.date.substring(4, 6),
      };

      // 연(Year) 검색 여부
      const dateOptionYearly = window.Number(dateOptions.month) === 0;

      const getSince = () => {
        if (dateOptionYearly) {
          return [dateOptions.year, "01", "01"].join("-");
        }

        return [dateOptions.year, dateOptions.month, "01"].join("-");
      };

      const getUntil = () => {
        if (dateOptionYearly) {
          return [dateOptions.year, "12", "31"].join("-");
        }

        const date = new Date();
        date.setFullYear(window.Number(dateOptions.year));
        date.setMonth(window.Number(dateOptions.month));
        date.setDate(1);
        date.setDate(date.getDate() - 1);

        return [dateOptions.year, dateOptions.month, date.getDate()].join("-");
      };

      const args = {
        since: getSince(),
        until: getUntil(),
        ref_name: "master",
        with_stats: true,
        all: true,
        trailers: true,
        sort: "committed_date_desc",
        per_page: 10000,
      };

      // 포함 커밋
      const includesCommits = [];
      const includeRequests = [];

      // 예외 커밋
      const excludesCommits = [];
      const excludeRequests = [];

      const keyRes = await http.get("/api/admin/keys/gitlab").catch(httpError());
      const options = {
        authorization: `Bearer ${keyRes.data.body}`
      };

      const getColor = (name) => {
        const colors = ["#000", "#2dce89", "#007bff", "#11cdef"];
        return colors[name.length % colors.length];
      };

      const getDetailArrIdx = (date) => {
        if (dateOptionYearly) {
          return date.getMonth();
        }

        // 일요일 기준의 주(week) 번호 조회
        const dayOfWeek = new Date(date.getFullYear(), date.getMonth(), 1).getDay();
        date.setDate(date.getDate() + dayOfWeek);
        return Math.floor((date.getDate() - 1) / 7);
      };

      const gitLabApiUrl = "https://gitlab.ohmycompany.com/api/v4/projects";

      for (let p1 of state.options.projects) {
        includeRequests.push(http.get(`${gitLabApiUrl}/${p1.id}/repository/commits`, args, options));

        for (let p2 of excludeProjectPaths) {
          p1.name === p2.name && excludeRequests.push(http.get(`${gitLabApiUrl}/${p1.id}/repository/commits?path=${p2.path}`, args, options));
        }
      }

      // skeleton
      state.statistics = [];
      for (let i = 0; i < 3; i += 1) {
        state.statistics.push({
          name: "Wait a moment",
          color: "",
          count: 10000,
          percent: 0,
          ratio: 0,
          active: true,
          details: {
            labels: [],
            datasets: [],
          },
        });
      }

      state.loaded = false;

      // 제외
      const excludeResponses = await Promise.all(excludeRequests);
      excludeResponses.forEach((res) => {
        excludesCommits.push(...res.data);
      });

      // 포함
      const includeResponses = await Promise.all(includeRequests);
      includeResponses.forEach((res) => {
        const commitsFiltered = res.data.filter(c1 => !excludesCommits.map(c2 => c2.id).includes(c1.id) && !c1.title.startsWith("Merge"));
        includesCommits.push(...commitsFiltered);
      });

      state.loaded = true;
      state.statistics = [];

      const members = {};
      const details = {};

      for (let c of includesCommits) {
        const authorName = c.author_name;
        const committedDate = new window.Date(c.committed_date);
        const detailArrIdx = getDetailArrIdx(committedDate);
        const totalStatsCount = c.stats.total;

        if (!members[authorName]) {
          members[authorName] = 0;
        }

        members[authorName] += totalStatsCount;

        if (!details[authorName]) {
          const length = dateOptionYearly ? 12 : 5;
          details[authorName] = [];

          for (let i = 0; i < length; i += 1) {
            details[authorName].push(0);
          }
        }

        details[authorName][detailArrIdx] += totalStatsCount;
      }

      if (!Object.keys(members).length) {
        return;
      }

      for (let name in members) {
        const labels = [];
        const values = [];
        const color = getColor(name);

        for (let i in details[name]) {
          labels.push((window.Number(i) + 1) + (dateOptionYearly ? "월" : "주차"));
          values.push(details[name][i]);
        }

        state.statistics.push({
          name: name,
          color: color,
          count: members[name],
          percent: 0,
          ratio: 0,
          active: true,
          details: {
            labels: labels,
            datasets: [{
              label: "",
              data: values,
              borderColor: color,
              backgroundColor: color,
            }]
          },
        });
      }

      state.statistics.sort((a, b) => {
        return b.count - a.count;
      });

      calculate();
    };

    watch(() => [router.app.$route.query.projects, router.app.$route.query.date], (next, prev) => {
      if (JSON.stringify(next) !== JSON.stringify(prev)) {
        load();
        scrollToTop();
      }
    });

    watch(() => [router.app.$route.query.graphs], (next, prev) => {
      JSON.stringify(next) !== JSON.stringify(prev) && setOptions();
    });

    return {component, state, lib, projects, graphs, dates, excludeProjectPaths, computedProjectName, computedGraph, load, toggleMember, toggleProject, toggleGraph, onChangeDate,};
  }
};
</script>

<style lang="scss" scoped>
.admin-development-commits {
  position: relative;
  padding-top: $px15;
  padding-bottom: $px20;

  .balloon {
    position: absolute;
    top: 0;
    left: 0;
    border-radius: $px4 $px4 0 $px4;
  }

  .subject {
    font-size: $px14;
    margin-bottom: $px25;

    > .text {
      font-weight: 600;
    }
  }

  > .header {
    position: absolute;
    top: $px-55;
    right: 0;

    > .search {
      position: relative;
      padding-right: $px56;

      select {
        color: inherit;
        height: $px43;
        width: $px150;
      }

      .btn, select {
        &:disabled {
          opacity: 1 !important;
          background-color: #e9ecef !important;
        }
      }

      i {
        font-size: $px14;
      }

      .row > .col {
        padding-left: 0;
        position: relative;

        .dropdown {
          width: 100%;

          .btn {
            font-size: $px12;
            border: $px1 solid $colorBorder;
            background: none;
            text-align: left;
            height: $px43;
            width: $px150;
            padding-left: $px15;
            padding-right: $px20;
            box-shadow: none;
            margin: 0;

            > div {
              overflow: hidden;
            }

            &:focus {
              border-color: $colorPurple;
            }

            &:after {
              position: absolute;
              top: 50%;
              right: $px13;
              transform: translateX(50%);
            }
          }

          .dropdown-menu {
            width: $px150;
            min-width: $px150;
            padding: $px5 0;

            a {
              cursor: pointer;
              font-size: $px12;

              i {
                opacity: 0.25;
                margin-right: $px7;
              }

              &.active {
                i {
                  opacity: 1;
                  color: $colorPurple;
                }
              }
            }
          }
        }

        > select {
          font-size: $px12;
        }

        .balloon {
          margin-left: $px-50;
          margin-top: $px-15;
        }
      }

      .projects {
        position: absolute;
        top: 0;
        right: $px54;
        width: $px145;

        > select {
          font-size: $px12;
        }
      }

      .refresh {
        position: absolute;
        top: 0;
        right: 0;
        height: $px43;
        z-index: 1;

        .wrapper {
          width: $px42;
          height: 100%;
          text-align: center;
          cursor: pointer;
          border: $px1 solid #dee2e6;
          padding: $px8 0;
          border-radius: $px4;
          position: relative;
          margin: 0;

          i {
            margin: 0;
          }
        }
      }
    }
  }

  .alerts {
    margin-bottom: $px30;
    display: flex;
    flex-direction: column;
    gap: $px15;

    .alert {
      background: #f7f7f7;
      border-color: #eee;
      font-size: $px14;
      margin-bottom: 0;
    }
  }

  .graphs {
    > .wrapper {
      display: flex;
      flex-direction: column;
      gap: $px10;

      .graph {
        display: flex;
        flex-direction: column;
        gap: $px3;
        opacity: 0.5;

        .bar {
          display: flex;
          flex-direction: column;
          gap: $px5;

          .top {
            display: flex;
            gap: $px3;

            .name {
              text-transform: uppercase;
            }

            .count {
              font-size: $px14;
            }

            .remove {
              opacity: 0.5;
              cursor: pointer;
              display: none;
            }
          }

          .progress {
            height: auto;

            .progress-bar {
              display: block;
              background-color: #eee;
              color: #eee;
              padding: $px12 $px5;
              min-width: $px76;
            }
          }
        }

        .line {
          margin-bottom: $px20;
        }

        &.active {
          opacity: 1;
        }

        &:hover {
          .top .remove {
            display: inline-block;

            &:hover {
              opacity: 1;
            }
          }
        }

        &:last-child .line {
          margin-bottom: 0;
        }
      }
    }
  }

  &.skeleton {
    .alert {
      @include skeleton
    }

    .graphs > .wrapper .graph {
      .bar {
        .top {
          .name, .count {
            @include skeleton
          }
        }

        .progress .progress-bar {
          @include skeleton
        }
      }
    }
  }

  @media(max-width: 991px) {
    > .header {
      position: static;
      padding-bottom: $px15;

      .search {
        padding-right: 0;

        .row {
          display: block;

          > .col {
            padding-left: $px15;
            margin-top: $px8;

            .balloon {
              left: 0;
              margin-top: $px-12;
              margin-right: 0;
              margin-left: $px-27;
            }

            .dropdown {
              .btn {
                width: 100%;
              }

              .dropdown-menu {
                width: 100%;
              }
            }

            select {
              width: 100%;
            }

            &.project .dropdown {
              .btn, .dropdown-menu {
                width: calc(100% - $px50);
              }
            }
          }
        }
      }
    }
  }
}
</style>